"use client";

import dynamic from "next/dynamic";
import { Provider } from "react-redux";

import { store } from "@Core/store";

import { ReactQueryWrapper } from "./ReactQueryWrapper";

const Modals = dynamic(() => import("@Modals"), {
    ssr: false,
});

export const ClientWrapper: React.FC<React.PropsWithChildren> = ({
    children,
}) => {
    return (
        <Provider store={store}>
            <ReactQueryWrapper>
                {children}

                <Modals />
            </ReactQueryWrapper>
        </Provider>
    );
};
