"use client";

import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { PAGES } from "@Settings/pages";
import { Button } from "@Components/Button";
import { Container } from "@Components/Container";
import { Link } from "@Components/Link";
import { useTranslation } from "@Core/i18n/client";
import useWindowSize from "@Hooks/windowSize";
import { openContactUsModal } from "@Modals/ContactUsModal";

export const Header: React.FC = () => {
    const dispatch = useDispatch();
    const { t: tCommon } = useTranslation();
    const { t: tHome } = useTranslation("home");

    const windowSize = useWindowSize();

    const refComponent = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (refComponent.current) {
            document.documentElement.style.setProperty(
                "--header-height",
                `${refComponent.current.offsetHeight}px`
            );
        }

        return () => {
            document.documentElement.style.setProperty("--header-height", "");
        };
    }, [windowSize.width]);

    const handleContactUsClick = () => {
        dispatch(openContactUsModal());
    };

    return (
        <header
            ref={refComponent}
            className="pointer-events-none fixed left-0 right-0 top-0 z-30 p-3 md:py-4 lg:px-4"
        >
            <Container
                className="pointer-events-auto items-center rounded-xl border border-white border-opacity-15 bg-total-black bg-opacity-20 p-2 text-white backdrop-blur-sm md:rounded-2xl md:p-3 lg:p-3"
                grid
            >
                <Link
                    className="col-span-1 ml-2 w-max text-[20px]/[110%] font-bold uppercase md:col-span-2 md:text-[24px]/[110%] md:transition-colors md:hover:text-bellflower-light"
                    href={PAGES.home.link}
                >
                    Violex
                </Link>

                <nav className="max-sm:hidden sm:col-span-6 sm:flex sm:flex-wrap sm:items-center sm:justify-center sm:gap-4 sm:text-p3 md:col-span-8 md:gap-8 md:text-p2">
                    {Object.entries(PAGES.home.sections).map(
                        ([sectionId, section]) => (
                            <Link
                                key={sectionId}
                                className="md:transition-colors md:hover:text-bellflower-light"
                                href={section.link}
                            >
                                {tHome(`sections.${sectionId}.name`)}
                            </Link>
                        )
                    )}
                </nav>

                <Button
                    className="col-span-2 w-max min-w-[122px] justify-self-end sm:col-span-1 md:col-span-2 md:min-w-[140px]"
                    size="sm"
                    onClick={handleContactUsClick}
                >
                    {tCommon("header.contactUsButton")}
                </Button>
            </Container>
        </header>
    );
};

Header.displayName = "Header";
