import {
    QueryClient,
    QueryClientConfig,
    QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React from "react";

const queryClientOptions = {
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            retry: 3,
        },
    },
} satisfies QueryClientConfig;

const queryClient = new QueryClient(queryClientOptions);

export const ReactQueryWrapper: React.FC<React.PropsWithChildren> = ({
    children,
}) => (
    <QueryClientProvider client={queryClient}>
        {children}
        <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
);
